/* Global styles */
body {
  font-family: 'Helvetica Neue', Arial, sans-serif;
  background-color: #f4f5f7;
  margin: 0;
  padding: 0;
  color: #333;
  text-align:center;
  
}

.App {
  text-align: center;
  font-family: Arial, sans-serif;
  margin: 5px;
}

/* Container for the entire dashboard */
.dashboard {
  max-width: 1200px;
  margin: 20px auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

/* Heading styles */
h2 {
  text-align: center;
  color: #444;
  font-size: 2.2rem;
  margin-bottom: 20px;
  font-weight: 600;
}

/* Control section (date picker, filter, chart type) */
.controls {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: 20px;
  align-items: center;
  margin-bottom: 30px;
}

/* Styled dropdowns and inputs */
.chart-type-selector,
.toggle-chart input {
  padding: 8px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #ffffff;
  cursor: pointer;
  transition: all 0.3s ease;
}

.chart-type-selector:hover,
.toggle-chart input:hover {
  border-color: #007bff;
}

/* Chart container */
.chart-container {
  height: 250px; /* Fixed height for the container */
  overflow: auto; /* Enable vertical scrolling */
  position: relative;
  border: 2px solid #4caf50; /* Green border */
  border-radius: 8px; /* Rounded corners */
  padding: 20px; /* Padding for inner content */
  margin-bottom: 40px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add box shadow for styling */
  background-color: #fff;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex; 
  justify-content: center; 
  align-items: flex-start; 
}

.chart-container:hover {
  transform: scale(1.02);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.chart-container canvas,
.chart-container svg {
  height: 5000px; /* Fixed height for the chart */
  width: 5000px; /* Adjust width proportionally */
}

/* Table container */
.table-container {
  overflow-x: auto;
  margin-top: 10px;
  max-height: 150px;
  overflow-y: auto;
  padding: 5px;
  border: 1px solid #ddd;
  background-color: #ffffff;
  border-radius: 8px;
}

/* Table styles */
.data-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 0.95rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.data-table th,
.data-table td {
  padding: 5px 7px;
  text-align: left;
  border: 1px solid #ddd;
}

.data-table th {
  background-color: #007bff;
  color: #ffffff;
  font-weight: bold;
}

.data-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.data-table tr:hover {
  background-color: #f1f1f1;
}

/* Error and loading messages */
.error,
.loading {
  text-align: center;
  font-size: 1.2rem;
  color: #ff4444;
}

/* Responsive styling */
@media (max-width: 768px) {
  .controls {
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: 10px;
  }

  .menu ul {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .menu ul li a {
    width: 100%;
  }
}

.chart-details-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
  flex-wrap: wrap;
  /* Responsive wrapping */
}

.chart-section {
  flex: 2;
  max-width: 65%;
  padding: 15px;
  background-color: #f9f9f9;
  border-radius: 8px;
  border: 1px solid #ddd;
}

.details-section {
  flex: 1;
  max-width: 30%;
  padding: 15px;
  background-color: #ffffff;
  border-radius: 8px;
  border: 1px solid #ddd;
}

.details-section h3 {
  margin-bottom: 10px;
  font-size: 1.2rem;
  color: #007bff;
}

.details-list {
  list-style-type: none;
  padding: 0;
}

.details-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.color-box {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  border: 1px solid #333;
  border-radius: 4px;
}

.main-body {
  max-height: calc(100vh - 150px); /* Adjust height based on your layout */
  overflow-y: auto;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #fff; /* Optional for better appearance */
  
}

.controls {
  display: inline-flex;
  flex-wrap: nowrap; /* Keep all elements in one line */
  align-items: center; /* Vertically align items */
  justify-content: flex-start; /* Align items to the left */
  gap: 500px; /* Add space between controls */
  padding: 10px; /* Add padding for better spacing */
  margin-bottom: 10px;
  font-size: 14px;
  
}

.chart-type-selector, 
.controls select {
  padding: 8px 12px; /* Make dropdowns larger and clickable */
  font-size: 14px; /* Slightly larger font for readability */
  border: 1px solid #ccc; /* Border to match other inputs */
  border-radius: 4px; /* Rounded corners */
  background-color: #fff; /* White background for dropdowns */
  transition: all 0.3s ease; /* Smooth interaction effect */
}

.chart-type-selector:hover,
.controls select:hover {
  border-color: #aaa; /* Highlight border on hover */
}

.controls button {
  padding: 8px 12px; /* Consistent padding with dropdowns */
  font-size: 14px; /* Match font size with dropdowns */
  border: none; /* Remove default button border */
  border-radius: 4px; /* Rounded corners for buttons */
  background-color: #007bff; /* Primary color */
  color: #fff; /* White text color */
  cursor: pointer; /* Pointer cursor for buttons */
  transition: background-color 0.3s ease; /* Smooth hover effect */
}

.controls button:hover {
  background-color: #0056b3; /* Darker shade on hover */
}

@media screen and (max-width: 768px) {
  .controls {
    flex-wrap: wrap; /* Allow wrapping on smaller screens */
    gap: 10px; /* Adjust spacing */
    justify-content: center; /* Center controls for smaller screens */
  }

  .chart-type-selector,
  .controls select {
    width: 100%; /* Full-width dropdowns for smaller screens */
  }
}
.data-table {
  width: 100%;
  border-collapse: collapse;
}

.data-table th,
.data-table td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.table-container {
  margin-bottom: 20px;
}
